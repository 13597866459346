import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StyledActionButton from "../../../styles/components/UI/ActionButton.Styled";
import SidebarMenu from "../../../components/UI/SidebarMenu";
import Documentation from "../../../styles/components/Documentation.Styled";
import CodeBloc from "../../../components/CodeBloc";

function DownloadProjectDocs({ open, setOpen, projectId }) {
    const closeDoc = () => {
        setOpen(false);
    };

    return (
        <SidebarMenu open={open} onClose={closeDoc} className="fixedSize">
            <Documentation>
                <h2 className="title">Ploomber Cloud Quickstart Guide</h2>

                <h2 className="subtitle">
                    Download and set up your project with Ploomber-Cloud CLI
                </h2>

                <div className="instruction">
                    <Typography>
                        To use Ploomber Cloud, follow these steps:
                    </Typography>
                </div>

                <div className="instruction">
                    <Typography>1. Install the CLI</Typography>
                    <CodeBloc>pip install ploomber-cloud</CodeBloc>
                </div>

                <div className="instruction">
                    <Typography variant="body1">2. Set your API key</Typography>
                    <CodeBloc>ploomber-cloud key YOURKEY</CodeBloc>
                </div>

                <div className="instruction">
                    <Typography>3. Download an existing project</Typography>
                    <CodeBloc>ploomber-cloud init --from-existing</CodeBloc>
                    <Typography>
                        Follow the prompts to select your project ({projectId}).
                    </Typography>
                </div>

                <div className="instruction">
                    <Typography>4. Edit your project</Typography>
                    <Typography>
                        Open the project directory in your favorite editor. Make
                        any necessary changes or improvements to your project
                        files.
                    </Typography>
                </div>

                <div className="instruction">
                    <Typography>5. Deploy the project</Typography>
                    <CodeBloc>
                        cd your-project-directory ploomber-cloud deploy --watch
                    </CodeBloc>
                </div>

                <div className="CenteredContainer">
                    <Typography variant="h6">That&apos;s it!</Typography>
                    <Typography variant="body2">
                        Your changes are now deployed on Ploomber Cloud.
                    </Typography>
                    <Link
                        to="https://docs.cloud.ploomber.io/en/latest/user-guide/cli.html"
                        target="_blank"
                        className="MuiButton-link"
                        rel="noopener noreferrer"
                    >
                        To Know More
                    </Link>
                </div>
            </Documentation>
        </SidebarMenu>
    );
}

DownloadProjectDocs.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
};

export default DownloadProjectDocs;
