import posthog from "posthog-js";
import { getUserSession } from "../features/user/Account";

enum Events {
    PageView = "$pageview",
}

// TODO: This is probably not the best way to set the pages we want to track events within them
// consider removing this outside (maybe set an id for each page in App?)
enum Pages {
    Applications = "Applications",
    CreateApplication = "CreateApplication",
    ApplicationStatus = "ApplicationStatus",
}

interface PostHogEventProperties {
    source: string;
    // distinctId: string;
    email?: string;
    metadata?: any;
}

const enableTelemetry = process.env.REACT_APP_PH_API_KEY !== undefined;

if (enableTelemetry) {
    const posthogAPIKey = process.env.REACT_APP_PH_API_KEY;
    if (posthogAPIKey) {
        posthog.init(posthogAPIKey, {
            api_host: "https://app.posthog.com",
            loaded: (_posthog) => {
                const distinctId = posthog.persistence?.props.distinct_id;
                _posthog.identify(distinctId);
            },
            autocapture: true,
            capture_pageview: false,
        });
    }
}

const getUserEmail = async () => {
    const session = await getUserSession();
    const { email } = session;
    return email;
};

const identifyUser = async () => {
    try {
        const email = await getUserEmail();
        const distinctId = posthog.persistence?.props.distinct_id;
        posthog.identify(distinctId, { email });
    } catch {
        // Empty
    }
};

// when name is starts with $ - it's posthog event
// otherwise it's a custom event in the following format - CloudApp-{name}
// where name is - {Page}-{Action}
const log = async (
    name: string,
    props?: {
        email?: string;
        includeUserDetails?: boolean;
        metadata?: any;
    }
): Promise<void> => {
    try {
        const includeUserDetails =
            props && "includeUserDetails" in props
                ? props.includeUserDetails
                : true;
        // const distinctId = posthog.persistence.props.distinct_id;

        const event = name.startsWith("$") ? name : `ApplicationsApp-${name}`;

        const properties: PostHogEventProperties = {
            source: "Applications",
            // distinctId,
        };

        if (props?.metadata) {
            properties.metadata = props.metadata;
        }

        if (includeUserDetails) {
            // // Identify user with internal ID
            // posthog.identify(userId)
            // // Set email or any other data
            // posthog.people.set({ email: email })
            const email = (props && props?.email) || (await getUserEmail());
            properties.email = email;
        }

        posthog.capture(event, properties);
    } catch (err) {
        // TODO: Handle failed telemetry
    }
};

async function saveSurveyAnwser(surveyID: string, surveyData: any) {
    try {
        posthog.capture(`SurveyCompleted-${surveyID}`, surveyData);
    } catch {
        // Empty
    }
}

const logout = () => {
    posthog.reset();
};

export default { log, logout, Events, Pages, saveSurveyAnwser, identifyUser };
