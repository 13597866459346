import React, { useState, useEffect } from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Switch,
    Link,
} from "@mui/material";
import PropTypes from "prop-types";
import UserValidation from "../hoc/userValidation";
import StyledAuthenticationContainer from "../../../styles/features/applications/AuthenticationContainer.Styled";
import InputWithValidation from "../../../components/UI/InputWithValidation";

const MIN_USERNAME_LEN = 3;
const MIN_PASSWORD_LEN = 4;

function AuthenticationController({
    className,
    featureId,
    authEnabled,
    onCredentialsUpdate,
}) {
    const [isUsernameValid, setIsValidUsername] = useState(true);
    const [isPasswordValid, setIsValidPassword] = useState(true);

    const [newUsername, setNewUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [receivedPreviousCredentials, setReceivedPreviousCredentials] =
        useState(false);

    const [usernamePlaceholder, setUsernamePlaceholder] = useState("");
    const [passwordPlaceholder, setPasswordPlaceholder] = useState("");
    const [isToggleOn, setIsToggleOn] = useState(false);

    const [touched, setTouched] = useState(false);

    const [forceUpdate, setForceUpdate] = useState(0);

    const forceStateUpdate = () => setForceUpdate((prev) => prev + 1);

    const handleCredentialsChange = () => {
        setTouched(true);
    };

    useEffect(() => {
        setUsernamePlaceholder("Username");
        setPasswordPlaceholder("Password");
    }, [touched]);

    useEffect(() => {
        let carryOverAuth;

        if (touched) {
            carryOverAuth = false;
        } else if (isToggleOn) {
            carryOverAuth = receivedPreviousCredentials;
        } else {
            carryOverAuth = false;
        }

        onCredentialsUpdate({
            carryOverAuth,
            useAuth: isToggleOn,
            areValid: carryOverAuth ? true : isUsernameValid && isPasswordValid,
            username: newUsername,
            password: newPassword,
        });
    }, [isUsernameValid, isPasswordValid, isToggleOn, touched, forceUpdate]);

    // new logic end
    const usernameValidator = (username) => {
        if (!touched) {
            return false;
        }

        let errorMessage;
        const spacesPattern = /\s/;

        if (username.length === 0) {
            errorMessage = `username is empty`;
        } else if (username.length > 0 && username.length < MIN_USERNAME_LEN) {
            errorMessage = `username is too short (The minimum allowed length is ${MIN_USERNAME_LEN} characters)`;
        } else if (spacesPattern.test(username)) {
            errorMessage = `username shouldn't include spaces`;
        }

        const isValid = !errorMessage;
        setIsValidUsername(isValid);
        forceStateUpdate();
        return errorMessage;
    };

    const passwordValidator = (password) => {
        if (!touched) {
            return false;
        }

        let errorMessage;
        const spacesPattern = /\s/;
        if (password.length === 0) {
            errorMessage = `password is empty`;
        } else if (password.length > 0 && password.length < MIN_PASSWORD_LEN) {
            errorMessage = `password is too short (The minimum allowed length is ${MIN_PASSWORD_LEN} characters)`;
        } else if (spacesPattern.test(password)) {
            errorMessage = `password shouldn't include spaces`;
        }

        const isValid = !errorMessage;
        setIsValidPassword(isValid);
        forceStateUpdate();

        return errorMessage;
    };

    useEffect(() => {
        setIsToggleOn(authEnabled);
        if (authEnabled) {
            setReceivedPreviousCredentials(true);
        }
    }, [authEnabled]);

    useEffect(() => {
        if (receivedPreviousCredentials) {
            setUsernamePlaceholder("●●●●●●");
            setPasswordPlaceholder("●●●●●●");
        }
    }, [receivedPreviousCredentials]);

    return (
        <FormControl
            feature-id={featureId}
            className={className}
            data-testid="set-auth"
            sx={{
                width: "100%",
                "& .Mui-disabled": {
                    backgroundColor: "#9e9e9e",
                },
                "& label": {
                    fontWeight: "600",
                    color: "#000",
                    marginBottom: "5px",
                },
            }}
        >
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                id="toggle-password-protection"
                                checked={isToggleOn}
                                onChange={(e) => {
                                    setIsToggleOn(e.target.checked);
                                    forceStateUpdate();
                                }}
                                color="info"
                            />
                        }
                        label="Enable password protection"
                        labelPlacement="start"
                        sx={{
                            margin: 0,
                            width: "fit-content",
                        }}
                    />
                </FormGroup>
            </FormControl>

            {isToggleOn && (
                <StyledAuthenticationContainer>
                    <FormControl sx={{ width: "50%" }} variant="outlined">
                        <InputWithValidation
                            id="username"
                            disabled={!isToggleOn}
                            onChange={(e) => {
                                setNewUsername(e.target.value);
                                handleCredentialsChange();
                            }}
                            placeholder={usernamePlaceholder}
                            value={newUsername}
                            label="username"
                            validator={usernameValidator}
                        />
                    </FormControl>
                    <FormControl sx={{ width: "50%" }} variant="outlined">
                        <InputWithValidation
                            id="password"
                            type="password"
                            disabled={!isToggleOn}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                handleCredentialsChange();
                            }}
                            placeholder={passwordPlaceholder}
                            value={newPassword}
                            label="password"
                            validator={passwordValidator}
                        />
                    </FormControl>
                </StyledAuthenticationContainer>
            )}

            <FormLabel
                data-testid="auth0-advertisement"
                sx={{
                    marginTop: "10px",
                }}
            >
                For advanced authentication features, check out our&nbsp;
                <Link
                    href="https://docs.cloud.ploomber.io/en/latest/user-guide/password.html#auth0-authentication"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="auth0-link"
                >
                    Auth0 integration.
                </Link>
            </FormLabel>
        </FormControl>
    );
}

AuthenticationController.defaultProps = {
    className: "",
    featureId: "",
    authEnabled: false,
    onCredentialsUpdate: () => {},
};

AuthenticationController.propTypes = {
    className: PropTypes.string,
    featureId: PropTypes.string,
    authEnabled: PropTypes.bool,
    onCredentialsUpdate: PropTypes.func,
};

export default UserValidation(AuthenticationController);
