import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { AppContext } from "../../context/AppContext";
import { getJobStatusChipColor } from "../../utils/uiUtils.ts";
import RefreshButton from "../../components/UI/RefreshButton";

import telemetry from "../../services/telemetry.ts";
import ploomberAPI from "../../services/ploomberAPI.ts";
import Grid from "../../components/UI/Grid";
import HelperTooltip from "../../components/HelperTooltip";
import { formatDate, parseErrorMessage } from "../../utils/utils.ts";
import StyledChip from "../../styles/components/Chip.Styled";
import LabelsGroup from "../../components/UI/LabelsGroup";
import ActionButton from "../../components/UI/ActionButton";
import ApplicationSettingsMenu from "./ApplicationSettingsMenu";

function JobStatusRenderer({ data, colDef }) {
    const { navigate } = useContext(AppContext);

    if (!data) {
        return null;
    }
    const { field } = colDef;
    let toReturn;

    const handleCellClick = (event) => {
        const dest = `/applications/${data.name || data.project_id}/${data.id}`;
        const middleMousebutton = event.button === 1;
        if (event.metaKey || event.ctrlKey || middleMousebutton) {
            window.open(dest, "_blank", "rel=noopener noreferrer");
        } else if (data.id !== "" && field !== "settings") {
            navigate(dest);
        }
    };

    // Render different content based on the column's field
    switch (field) {
        case "id":
            toReturn = data.isLoading ? (
                <CircularProgress size={20} />
            ) : (
                <span>{data.id}</span>
            );
            break;

        case "project_id":
            // Render content for "Project" column
            toReturn = <span>{data.name || data.project_id}</span>;
            break;

        case "settings":
            toReturn = (
                <ApplicationSettingsMenu
                    projectId={data.project_id}
                    jobId={data.id}
                    jobStatus={data.status}
                    projectName={data.name}
                    applicationUrl={data.url}
                    enableAnalyticsDashboard
                    canRedeploy={data.canRedeploy}
                />
            );
            break;

        case "status":
            // Render content for "Status" column
            toReturn = (
                <div className="StatusCell">
                    {!data.isLoading && (
                        <StyledChip
                            className="Capitalize"
                            label={data.status}
                            color={getJobStatusChipColor(data.status)}
                            size="small"
                        />
                    )}
                </div>
            );
            break;
        case "labels":
            toReturn = <LabelsGroup labels={data.labels || []} max={1} />;
            break;
        case "type":
            toReturn = <span>{data?.type}</span>;
            break;
        case "created":
            toReturn = <span>{formatDate(data?.created)}</span>;
            break;

        case "application_link":
            if (data.status === "running") {
                toReturn = (
                    <ActionButton
                        id={`${data.id}-view-application-button`}
                        variant="outline"
                        size="small"
                        endIcon={<OpenInNewIcon />}
                        onClick={(e) => {
                            window.open(data.url, "_blank");
                            e.stopPropagation();
                        }}
                    >
                        View application
                    </ActionButton>
                );
            }

            break;
        default:
            return null;
    }

    return (
        <div
            role="button"
            onKeyPress={(e) => {}}
            tabIndex="0"
            onClick={handleCellClick}
            onAuxClick={handleCellClick}
        >
            {toReturn}
        </div>
    );
}

JobStatusRenderer.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        project_id: PropTypes.string,
        status: PropTypes.string,
        isLoading: PropTypes.bool,
        created: PropTypes.string,
        type: PropTypes.string,
        labels: PropTypes.arrayOf(PropTypes.string),
        url: PropTypes.string,
        name: PropTypes.string,
        canRedeploy: PropTypes.bool,
    }),
    colDef: PropTypes.shape({
        field: PropTypes.string.isRequired, // Assuming field is a required string
    }).isRequired,
};

JobStatusRenderer.defaultProps = {
    data: null,
};

// defaultPagesToFetch determines how many pages are queried whenever the Grid Component requests more server side data
// Balance between server side payload size vs UI responsive, adjust accordingly
const defaultPageSize = 10;
const defaultPagesToFetch = 1;
const cacheBlockSize = defaultPageSize * defaultPagesToFetch;

function Applications() {
    const { updateSnackbarStatus } = useContext(AppContext);
    const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
    const [enableCellTextSelection, setEnableCellTextSelection] =
        useState(true);
    const [firstPageData, setFirstPageData] = useState([]);

    const columnsDefs = [
        {
            field: "id",
            headerName: "Job Id",
            cellRenderer: JobStatusRenderer,
            suppressMenu: true,
            width: 100,
        },
        {
            field: "project_id",
            headerName: "Application",
            cellRenderer: JobStatusRenderer,
            suppressMenu: true,
            width: 150,
        },
        {
            field: "status",
            cellRenderer: JobStatusRenderer,
            suppressMenu: true,
            width: 200,
        },
        {
            field: "created",
            cellRenderer: JobStatusRenderer,
            flex: 1,
            suppressMenu: true,
        },
        {
            field: "type",
            cellRenderer: JobStatusRenderer,
            suppressMenu: true,
            width: 100,
        },
        {
            field: "labels",
            cellRenderer: JobStatusRenderer,
            flex: 1,
            suppressMenu: true,
        },
        {
            field: "settings",
            headerName: "",
            cellRenderer: JobStatusRenderer,
            width: 80,
            suppressMenu: true,
        },
        {
            field: "application_link",
            headerName: "",
            cellRenderer: JobStatusRenderer,
            suppressMenu: true,
            flex: 1,
        },
    ];

    const gridRef = useRef(null);

    const containerStyle = useMemo(
        () => ({ width: "100%", height: "100%", marginBottom: 70 }),
        []
    );

    const gridStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

    const fetchFirstPageData = () => {
        ploomberAPI
            .getUserProjects(cacheBlockSize, 0)
            .then((_jobs) => {
                // Update the jobCount state variable
                setFirstPageData(_jobs);
                setInitialFetchCompleted(true);
            })
            .catch((err) => {
                updateSnackbarStatus({
                    message: parseErrorMessage(err),
                    severity: "error",
                });
            });
    };

    useEffect(() => {
        fetchFirstPageData();
        telemetry.log(telemetry.Events.PageView);
        telemetry.identifyUser();
    }, []);

    function refreshApplications() {
        setInitialFetchCompleted(false);
        fetchFirstPageData();
    }

    const dataSource = {
        getRows: (params) => {
            const currentPage = Math.floor(params.startRow / cacheBlockSize);
            const isFirstPage = currentPage === 0;

            const flatJobs = (projects) => {
                // Pick the first job within same project as the latest job
                const uniqueJobs = [];
                projects.forEach((project) => {
                    let firstJob = project.jobs.sort((a, b) => {
                        const dateA = new Date(a.created).getTime();
                        const dateB = new Date(b.created).getTime();
                        return dateB - dateA;
                    })[0];

                    if (firstJob === undefined || firstJob === null) {
                        firstJob = {
                            id: "",
                            status: "not-deployed",
                            project_id: project.id,
                            created: String(new Date()),
                        };
                    }

                    firstJob.name = project.name;
                    firstJob.type = project.type;
                    firstJob.labels = project.labels;
                    firstJob.url = project.url;
                    firstJob.canRedeploy = project.can_redeploy;

                    uniqueJobs.push(firstJob);
                });

                return (
                    uniqueJobs
                        .filter((job) => job !== undefined)
                        .map((job) => ({
                            id: job.id,
                            status: job.status,
                            project_id: job.project_id,
                            created: job.created,
                            type: job.type,
                            labels: job.labels,
                            url: job.url,
                            name: job.name,
                            canRedeploy: job.canRedeploy,
                        })) || []
                );
            };
            if (isFirstPage && initialFetchCompleted) {
                const jobs = flatJobs(firstPageData.projects);
                params.successCallback(jobs, firstPageData.count);
                gridRef.current?.api?.setDomLayout("autoHeight");
                return;
            }

            // Render a Loader Row
            params.successCallback([{ isLoading: true }], firstPageData.count);

            // Calculate the current page based on the requested rows
            ploomberAPI
                .getUserProjects(cacheBlockSize, currentPage)
                .then((_jobs) => {
                    const jobs = flatJobs(_jobs.projects);
                    params.successCallback(jobs, firstPageData.count);

                    if (jobs.length > 0) {
                        gridRef.current?.api?.hideOverlay();
                    }
                })
                .catch((err) => {
                    updateSnackbarStatus({
                        message: parseErrorMessage(err),
                        severity: "error",
                    });
                    params.failCallback();
                })
                .finally(() => {
                    gridRef.current?.api?.setDomLayout("autoHeight");
                });
        },
    };

    return (
        <div data-testid="applications-1">
            <div style={containerStyle}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 id="applicationsHeader">Applications</h2>
                        <HelperTooltip text="Create new application">
                            <Button
                                data-testid="create-new-application"
                                style={{ margin: "auto 20px" }}
                                component={Link}
                                to="create"
                                variant="contained"
                                color="primary"
                                id="newApplicationButton"
                            >
                                New
                            </Button>
                        </HelperTooltip>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <HelperTooltip text="Fetch applications">
                            <RefreshButton
                                onClick={() => {
                                    telemetry.log(
                                        `${telemetry.Pages.Applications}-RefreshClick`
                                    );
                                    refreshApplications();
                                }}
                            />
                        </HelperTooltip>
                    </div>
                </div>
                {!initialFetchCompleted ? (
                    <CircularProgress />
                ) : (
                    <Grid
                        id="dashboardsGrid"
                        style={gridStyle}
                        ref={gridRef}
                        columnDefs={columnsDefs}
                        onGridReady={(params) => {
                            params.api.setDatasource(dataSource);
                        }}
                        suppressPaginationPanel={
                            firstPageData.count < defaultPageSize
                        }
                        paginationPageSize={defaultPageSize}
                        cacheBlockSize={cacheBlockSize}
                        frameworkComponents={{
                            jobStatusRenderer: JobStatusRenderer, // Register the JobStatusRenderer component
                        }}
                        onApplicationDeleted={() => {
                            refreshApplications();
                        }}
                        enableCellTextSelection={enableCellTextSelection}
                    />
                )}
            </div>
        </div>
    );
}

export default Applications;
