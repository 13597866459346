import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Documentation = styled(Box)(({ theme }) => ({
    "& .MuiTypography-body1": {
        fontSize: "0.85rem",
    },

    "& .MuiTypography-body2": {
        fontSize: "0.75rem",
    },

    "& .MuiTypography-h6": {
        fontSize: "1rem",
    },

    "& .MuiButton-link": {
        color: "black",
        fontSize: "0.75rem",
        textDecoration: "underline",
        marginTop: "0.2rem",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    },

    "& > .instruction": {
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        marginBottom: "2rem",
        gap: "0.3rem",
    },

    "& .CenteredContainer": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },

    "& > .title": {
        fontSize: "1.5rem",
        marginTop: "1rem",
        marginBottom: "1rem",
    },

    "& > .subtitle": {
        fontSize: "1rem",
        marginTop: "0rem",
        marginBottom: "0rem",
    },
}));

export default Documentation;
