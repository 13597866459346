import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import {
    Box,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    ListItem,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    MobileStepper,
} from "@mui/material";
import List from "@mui/material/List";
import { ActionButton } from "../../../components/UI";

import { AppContext } from "../../../context/AppContext";
import { cancelSubscription } from "../../../utils/utils.ts";
import { AccountContext } from "../../user/Account";
import { UserType } from "../../../models/enum.ts";
import proUserConfiguration from "../../user/configurations/pro.json";
import teamsUserConfiguration from "../../user/configurations/teams.json";

function SubscriptionContentAndActionsRenderer({ open, onClose }) {
    const [openDialog, setOpenDialog] = useState(open);
    const { updateSnackbarStatus } = useContext(AppContext);

    const [activeStep, setActiveStep] = useState(0);
    const [selectedReason, setSelectedReason] = useState("");

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const { userType, setSubscription, subscription } =
        useContext(AccountContext);

    const handleSubmit = () => {
        cancelSubscription(updateSnackbarStatus, selectedReason, (sub) => {
            setSubscription(sub);
        });
        onClose();
    };

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setActiveStep(0);
                setSelectedReason("");
            }, 500);
        }
    }, [open]);

    const cancellationReasons = [
        "I need more features",
        "It's too expensive",
        "Quality was less than expected",
        "Not needed anymore",
        "Found a better alternative",
        "Ease of use was less than expected",
        "Customer service was less than expected",
        "Other",
    ];

    let lostFeatures = [];
    if (userType === UserType.PRO.value) {
        lostFeatures = proUserConfiguration.features;
    } else if (userType === UserType.TEAMS.value) {
        lostFeatures = teamsUserConfiguration.features;
    }

    return (
        <>
            <DialogContent>
                {activeStep === 0 && (
                    <Box className="Subtitle">
                        Are you sure you want to cancel your subscription?
                    </Box>
                )}
                {activeStep === 1 && (
                    <Box className="Subtitle">
                        Please select a reason for cancellation:
                    </Box>
                )}
            </DialogContent>
            <DialogContent>
                <DialogContentText component="div" color="#000">
                    {activeStep === 0 && (
                        <Box className="ScrollableBox">
                            <List>
                                {lostFeatures.map((item, index) => (
                                    <ListItem className="ListItems" key={index}>
                                        <Typography variant="body1">
                                            {index + 1}. {item}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box className="ScrollableBox">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={selectedReason}
                                    onChange={handleReasonChange}
                                >
                                    {cancellationReasons.map((reason) => (
                                        <FormControlLabel
                                            key={reason}
                                            value={reason}
                                            control={<Radio />}
                                            label={reason}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="Footer">
                <Box className="Buttons">
                    <ActionButton onClick={onClose} variant="text">
                        CANCEL
                    </ActionButton>

                    {activeStep === 0 && (
                        <ActionButton
                            data-testid="confirmCancelSubscriptionButton"
                            onClick={() => setActiveStep(1)}
                            variant="contained"
                        >
                            NEXT
                        </ActionButton>
                    )}
                    {activeStep === 1 && (
                        <ActionButton
                            data-testid="subscriptionCancelReasonButton"
                            color="error"
                            variant="contained"
                            disabled={selectedReason === ""}
                            onClick={handleSubmit}
                        >
                            CANCEL SUBSCRIPTION
                        </ActionButton>
                    )}
                </Box>

                <Box>
                    <MobileStepper
                        variant="dots"
                        steps={2}
                        position="static"
                        activeStep={activeStep}
                    />
                </Box>
            </DialogActions>
        </>
    );
}

SubscriptionContentAndActionsRenderer.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.func.isRequired,
};

export default SubscriptionContentAndActionsRenderer;
