import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSettingsLayout = styled(Grid)(({ theme }) => ({
    "& .ag-theme-material": {
        height: "100%",
        width: "100%",
    },

    "& .LoaderContainer": {
        width: 20,
        marginTop: -100,
    },

    "& .Title": {
        marginTop: 10,
    },

    "&#accountDetails": {
        width: "100%",
        maxWidth: "100%",
        marginTop: "1rem",
        // transition: 'all 0.3s ease-out',
        [theme.breakpoints.up("md")]: {
            maxWidth: "70%",
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "60%",
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: "50%",
        },
    },

    "& .InputContainer": {
        width: "100%",
    },

    "& .InputWithCopy": {
        marginBottom: theme.spacing(3),
        width: "100%",
    },
}));

export default StyledSettingsLayout;
