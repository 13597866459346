import { JobStatus, UserType } from "../models/enum.ts";
import { ErrorMessage } from "../models/interfaces.ts";
import ploomberAPI from "../services/ploomberAPI.ts";
import telemetry from "../services/telemetry.ts";

export const parseErrorMessage = (error: ErrorMessage): string =>
    `${error?.title ?? "Internal error"} - ${
        error?.detail ??
        "Please contact us on slack for support, link on the bottom"
    }`;

export const capitalize = (text: string): string =>
    text.length > 0 ? `${text[0].toUpperCase()}${text.slice(1)}` : text;

export const setAccessToken = (token) => {
    localStorage.setItem("ploomber_token", token);
};
export const getAccessToken = () => localStorage.getItem("ploomber_token");

export const isBareURL = (input: string): boolean => {
    // Regular expression to match a URL without protocol, www, subdomains, or query params
    const urlPattern = /^[^/:]+\.[^/:]+$/;

    return urlPattern.test(input);
};

export function formatDate(inputDate: string): string {
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const utcFormat = new Date(`${inputDate}Z`);
    const formattedDate: string = new Date(utcFormat).toLocaleDateString(
        "en-US",
        options
    );
    return formattedDate;
}

export class CustomError extends Error {
    title: string;

    detail: string;

    constructor(
        title: string,
        detail: string,
        message: string = "CustomError"
    ) {
        // Pass the error message to the base class (Error)
        super(message);

        // Set the prototype explicitly to ensure correct instanceof checks
        Object.setPrototypeOf(this, CustomError.prototype);

        // Set additional properties
        this.title = title;
        this.detail = detail;

        // Capture the stack trace
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomError);
        }
    }
}

export const extractLatestTimestamp = (logString) => {
    const logEntries = logString.split("\n");
    const timestamps = logEntries.map((entry) => {
        const match = entry.match(
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}000/
        );
        return match ? new Date(match[0]).getTime() : 0;
    });
    return Math.max(...timestamps);
};

export const dispatchComponentReadyEvent = () => {
    const event = new CustomEvent("onComponentReady");
    document.dispatchEvent(event);
};

export const isSubdomain = (domain: string, domainList: Array<string>) => {
    const domainSplit = domain.split(".");

    if (domainSplit.length < 3) {
        return false;
    }
    const domainSuffixToCheck = domain.slice(domain.indexOf(".") + 1);

    for (let i = 0; i < domainList.length; i++) {
        if (domainSuffixToCheck === domainList[i]) {
            return true;
        }
    }
    return false;
};

export const getSubdomains = (
    domain: string,
    domainList: Array<string>
): Array<string> => {
    const subdomains = domainList.filter(
        (d) => d.endsWith(domain) && d !== domain
    );
    return subdomains;
};

export const isMultiLevelSubdomain = (
    domain: string,
    domainList: Array<string>
): boolean => {
    for (let i = 0; i < domainList.length; i++) {
        if (domain.endsWith(domainList[i])) {
            const urlPrefix = domain.slice(0, domain.indexOf(domainList[i]));
            const parts = urlPrefix.split(".");
            if (parts.length > 2) {
                return true;
            }
            return false;
        }
    }
    return false;
};

export const shouldValidateAction = (currentPlan: string, newPlan: string) => {
    let shouldValidate = false;

    const plans = Object.values(UserType)
        .filter((userType) => userType.value !== "admin")
        .map((userType) => userType.value);

    const newIndex = plans.indexOf(newPlan);
    const currentIndex = plans.indexOf(currentPlan);

    if (newIndex === -1 || currentIndex === -1) {
        shouldValidate = false;
    } else {
        shouldValidate = newIndex < currentIndex;
    }

    return shouldValidate;
};

export const cancelSubscription = async (
    updateSnackbarStatus,
    reason,
    callback
) => {
    try {
        const sub = await ploomberAPI.cancelSubscription(reason);
        updateSnackbarStatus({
            message: "Successfully canceled Ploomber Cloud subscription",
        });

        if (callback && typeof callback === "function") {
            callback(sub);
        }
    } catch {
        updateSnackbarStatus({
            message: "Failed to Update Cancelation Billing Period",
            severity: "error",
        });
    } finally {
        telemetry.log("Cancel Subscription");
    }
};

export const getFirstExampleByFramework = (
    framework: string,
    examples: object
) => {
    if (examples && framework in examples && examples[framework].length > 0) {
        return examples[framework][0];
    }
    return null;
};
