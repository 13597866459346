import React, { useContext, useState, useMemo } from "react";
import "./css/style.css";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import { AppContext } from "../../../../context/AppContext";
import telemetry from "../../../../services/telemetry.ts";
import RelaunchPageMessage from "./components/RelaunchPageMessage";

function ProjectRecoveryView({ onSuccess, onFailure, originalURL }) {
    const appContext = useContext(AppContext);
    const { accountContext } = appContext;
    const { restartProject } = accountContext;
    const { checkProjectStatus } = accountContext;
    const [appRelaunched, setAppRelaunched] = useState(false);

    const projectId = useMemo(() => {
        const urlParts = originalURL.split(".");
        const userProjectName = urlParts[0].split("https://")[1];
        return userProjectName;
    }, [originalURL]);

    const checkStaticProjectIsUp = async (pid) => {
        // Check the url status and throw error if not ready
        const checkUrlStatus = async () => {
            const response = await checkProjectStatus(pid);
            if (response) {
                // Response is either true/false
                onSuccess();
            }

            console.log(`URL is not ready yet. Retrying in 5 seconds...`);
            throw new Error("URL not ready"); // Retry after 5 seconds
        };

        let fetchJobTotalTimeout = 60;
        const fetchJobTimeout = 5000;
        const retryCheckUrl = async () => {
            if (fetchJobTotalTimeout > 0) {
                fetchJobTotalTimeout -= 1;
                // A wrapper to check URL and retry with timeout
                try {
                    console.log("Remaining retries: ", fetchJobTotalTimeout);
                    await checkUrlStatus();
                } catch (error) {
                    setTimeout(retryCheckUrl, fetchJobTimeout);
                }
            } else {
                // Error, timeout
                setAppRelaunched(false);
                onFailure({
                    title: "Application Launch Timeout",
                    detail: "The application is up check timed out, please try again or contact us via slack.",
                });
            }
        };

        await retryCheckUrl();
    };

    const handleProjectRecovery = async (e) => {
        e.preventDefault();
        setAppRelaunched(true);

        try {
            // Log via telemetry
            telemetry.log(`Project ${projectId} requested relaunch`);
            await restartProject(projectId);

            // Check if the project is up
            const result = await checkStaticProjectIsUp(projectId, onFailure);

            // Check if the project is up, this is never called since the check is async.
            if (result) {
                onSuccess(originalURL);
            }
        } catch (err) {
            setAppRelaunched(false);
            onFailure(err);
        }
    };

    return (
        <section className="main-content" redirect-url={originalURL}>
            <div className="login-card">
                <div className="registration-section">
                    <h1
                        className="form-header playfair-font"
                        id="restartApplicationHeader"
                    >
                        Restart Application
                    </h1>
                    <form id="launchApp" onSubmit={handleProjectRecovery}>
                        <button
                            type="submit"
                            className="btn-next"
                            data-testid="relaunch-button"
                            disabled={appRelaunched}
                        >
                            {appRelaunched && (
                                <CircularProgress
                                    size={15}
                                    style={{ marginRight: 10 }}
                                    color="neutral"
                                />
                            )}
                            {appRelaunched
                                ? "Spinning up..."
                                : "Spin up your application"}
                        </button>
                    </form>
                    <Box paddingTop="1rem">
                        <RelaunchPageMessage projectId={projectId} />
                    </Box>
                </div>
            </div>
        </section>
    );
}

ProjectRecoveryView.propTypes = {
    originalURL: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default ProjectRecoveryView;
