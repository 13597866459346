import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import SidebarMenu from "../../../components/UI/SidebarMenu";
import Documentation from "../../../styles/components/Documentation.Styled";
import CodeBloc from "../../../components/CodeBloc";

function GithubDeploymentDocs({ open, setOpen }) {
    const [token, setToken] = useState("PLOOMBER_CLOUD_KEY");

    useEffect(() => {
        const displayToken = async () => {
            const tokenList = await ploomberAPI.listAPIKeys();
            if (tokenList && tokenList.length > 0) {
                setToken(tokenList[0].api_key);
            }
        };
        displayToken();
    }, []);

    const closeDoc = () => {
        setOpen(false);
    };

    return (
        <SidebarMenu open={open} onClose={closeDoc} className="fixedSize">
            <Documentation>
                <h2 className="title">
                    GitHub Deployment Setup for your Project
                </h2>

                <h2 className="subtitle">
                    Set up GitHub deployment for your Ploomber Cloud project
                </h2>
                <div className="instruction">
                    <Typography>
                        Follow these steps to configure GitHub Actions for
                        deploying your project on each push:
                    </Typography>
                </div>
                <div className="instruction">
                    <Typography>
                        1. Make sure to have the Ploomber Cloud CLI install
                    </Typography>
                    <CodeBloc>
                        # Install the CLI
                        <br />
                        pip install ploomber-cloud
                        <br />
                        <br />
                        # Connect the CLI to your account
                        <br />
                        ploomber-cloud key {token}
                    </CodeBloc>
                </div>
                <div className="instruction">
                    <Typography>2. Set up GitHub secret</Typography>
                    <Typography>
                        Store your Ploomber Cloud API key as a GitHub secret
                        named <code>PLOOMBER_CLOUD_KEY</code> your repository.
                    </Typography>
                    <CodeBloc>
                        # GitHub repository secret for Ploomber Cloud
                        authentication
                        <br />
                        PLOOMBER_CLOUD_KEY = {token}
                    </CodeBloc>
                </div>
                <div className="instruction">
                    <Typography>
                        3. Download your ploomber-cloud.json config in your repo
                    </Typography>
                    <CodeBloc>
                        ploomber-cloud init --from-existing --only-config
                    </CodeBloc>
                </div>
                <div className="instruction">
                    <Typography>4. Set up GitHub Actions</Typography>
                    <Typography>
                        Run the following command in your repository folder:
                    </Typography>
                    <CodeBloc>ploomber-cloud github</CodeBloc>
                    <Typography>
                        This will create a ploomber-cloud.yaml file in
                        .github/workflows/.
                    </Typography>
                </div>
                <div className="instruction">
                    <Typography>5. Commit and push the new files</Typography>
                    <CodeBloc>
                        git add ploomber-cloud.json
                        .github/workflows/ploomber-cloud.yaml <br />
                        git commit -m &apos;configure github actions
                        deployment&apos; <br />
                        git push
                    </CodeBloc>
                </div>
                <div className="instruction">
                    <Typography>6. Monitor deployment</Typography>
                    <Typography>
                        Check the Actions tab in your GitHub repository to
                        monitor the deployment progress.
                    </Typography>
                </div>
                <div className="CenteredContainer">
                    <Typography variant="body2">
                        For more detailed information on GitHub deployment and
                        advanced configurations, please refer to the full
                        documentation.
                    </Typography>
                    <Link
                        to="https://docs.cloud.ploomber.io/en/latest/user-guide/github.html"
                        target="_blank"
                        className="MuiButton-link"
                        rel="noopener noreferrer"
                    >
                        To Know More
                    </Link>
                </div>
            </Documentation>
        </SidebarMenu>
    );
}

GithubDeploymentDocs.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default GithubDeploymentDocs;
